<template>
  <div class="slider-wrapper">
    <h1 class="smallTitle"  v-if="language.name === 'english'">Previous consultation timeline</h1>
    <h1 class="smallTitle"  v-if="language.name === 'welsh'">Llinell amser yr ymgynghoriad blaenorol</h1>
    <VueSlickCarousel v-if="timelineContentData[0]" v-bind="settings" :arrows="true" :dots="true">         
      <div v-for="(data, index) in timelineContentData"   :key="`timeline_date_${index}`">
            <div class="slider-component">
                <h4 v-html="data.header"></h4>
                <p v-html="data.text"></p>
                 <p class="link" v-if="data.link && data.link==true"><a :href="data.linkLink"  class="atag" v-html="data.linkText"></a>  <i class="fas fa-angle-right"></i>  </p>
            </div>
            <span></span>
      </div>
   </VueSlickCarousel>
  </div>
</template>
 
<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
 const _ = require('lodash');
import { mapGetters } from "vuex";
  export default {
    name: 'MyComponent',
    components: { VueSlickCarousel },
    data(){
        return{
          settings:  {
            "arrows":true,
            "dots": true,
            "focusOnSelect": true,
            "infinite": true,
            "speed": 500,
            "slidesToShow": 3,
            "slidesToScroll": 1,
            "touchThreshold": 5,
            "responsive": [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                  
                },
                {
                  breakpoint: 680,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
             ]
            }
        }
    },
    methods:{
        reInit () {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick()
      })
    },
    },
    computed: {
    ...mapGetters(["content","language"]),
        timelineContentData() {
        
        if (this.content && this.content.sliderData) {
            return _.orderBy(this.content.sliderData, 'date', 'desc');
        }else return {};
        },
    },
    beforeUpdate() {
        if (this.$refs.slick) {
            this.$refs.slick.destroy();
        }
    },
    updated() {
        this.$nextTick(function () {
            if (this.$refs.slick) {
                this.$refs.slick.create(this.slickOptions);
            }
        });
    },
  }
</script> 
<style lang="scss">
.slider-wrapper{
  h1{
    font-size: 27px;
    margin-bottom: 20px;
  }
}
.slider-component{
    border-top: 2px solid #327d72;
    padding-top: 20px;
    &::before{
        content:'';
        width:6px;
        height:6px ;
        color:green;
    }
}
.slider-component{
 margin-top: 15px;
 cursor: grab;
 h4{
    font-size: 24px !important;
    font-family: DM Serif Text;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #000 !important;
    }
 p{
   padding: 0 20px 0 0;
   color: #000 !important;
 }
 .link{
    font-size: 16px;
    color:   #2A8476;
    line-height:26px;
    text-decoration: none;
    text-align: left; 
  }
  a{
      color:   #2A8476;
  }
  i{
    font-size: 13px;
    padding-left: 2px;
    color:   #2A8476;
  }
}

.slider-component::before{
  position: absolute;
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  background: #327d72;
  margin-top: -32px;
}
.slick-dots li button:before{
  font-size: 15px;
}
.slick-dots li button:before{
  color: #327d72;
}
.slick-dots li.slick-active button:before{
  opacity: 1;
  color: #327d72;
}
.slick-prev:before, .slick-next:before{
  color: #327d72;
}
.slick-prev, .slick-next{
  top:-36px;
}
.slick-prev{
  right:30px;
  left: auto;
}
.slick-next{
  right: -5px;
}
</style>