<template>

              <section class="single-center-column">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 img-card-wrap">
                         <img :src="ImageSrc" :alt="imagesrc"/>
                        </div>
                        <div class="col-md-5 image-text-dimension" >
                        <div class="fl card-section" > 
                              <TitleText class="title-head"
                                  :smallTitle="true"
                                  :title="title"
                                  :text="text"
                                /> 
                        <div class="fl w100">
                           <div class="fl w100 txt-b pb-2">{{desc1}}</div>
                           <div class="fl w100 list-item-holder" v-for="(item,index) in viewpointtypeData" :key="index" >
                              <input type="radio"  name="viewpoint" v-model="viewpoint" :id="item.slug" v-bind:value="item.slug" v-on:change="filteredData()">
                              <label :for="item.slug">{{item.title}}</label>
                           </div>
                        </div>
                      <div class="fl w100 mt-3">
                        <div class="fl w100 txt-b pb-2">{{desc2}}</div>
                        <div class="fl list-item-holder mr-3 mobile-W100" v-for="(item,index) in tidetypeData" :key="index" >
                          <input type="radio" name="Tide" v-model="Tide" :id="item.slug" v-bind:value="item.slug" v-on:change="filteredData()">
                          <label  :for="item.slug">{{item.title}}</label>
                        </div>
                      </div>
                       <div class="fl w100 mt-3 mb-3">
                            <div class="fl w100 txt-b pb-2">{{desc3}}</div>
                            <div class="fl list-item-holder mr-3 mobile-W100" v-for="(item,index) in yearTypeData" :key="index" >
                              <input type="radio" name="Year" v-model="Year" :id="item.slug" v-bind:value="item.slug" v-on:change="filteredData()">
                              <label  :for="item.slug">{{item.title}}</label>
                            </div>
                        </div> 
                      </div>     
                   </div>
                    </div>
                </div>
            </section>

</template>
<script>
import TitleText from "../components/TitleText";

import { mapGetters } from "vuex";

const _ = require('lodash');
export default {
  name: "ImageSlider",
   props: {
    title: {
      type: String,
      default: " ",
    },
    text: {
      type: String,
      default: "",
    },
    
    smallTitle: {
      type: Boolean,
      default: false,
    },
    desc1: {
      type: String,
      default: "Select a viewpoint",
    },
    desc2: {
      type: String,
      default: "Select a tide",
    },
    desc3: {
      type: String,
      default: "Select a year",
    },
   
  },
  components: {
    TitleText,
    //HorizontalTimeline
  },
   computed: {
    ...mapGetters(["content"]),
   
     tidetypeData() {
        if (this.content && this.content.tidetype) {
            return _.orderBy(this.content.tidetype, 'orderNumber', 'asc');
          
        }else return {};
    },
     viewpointtypeData() {
        if (this.content && this.content.viewpointtype) {
           return _.orderBy(this.content.viewpointtype, 'orderNumber', 'asc');
            
        }else return {};
    },
     yearTypeData() {
        if (this.content && this.content.yearType) {
            return _.orderBy(this.content.yearType, 'orderNumber', 'asc');
            
        }else return {};
    },
     imageSliderData() {
        if (this.content && this.content.imageSlider) {

           console.log("ImageSlider",this.content.imageSlider);
            return this.content.imageSlider
        }else return {};
    },

   },
  
  data() {
      return {
          viewpoint:'newgale-village-and-Brandy-Brook',
          Tide: 'low-Tide',
           Year:'present-Day',
          ImageSrc:''
      }
    },
  created : function() { 
       this.filteredData()
  },

   methods: {
     
      filteredData(){
         let filterviewpoint= this.viewpoint;
            let filterTideType= this.Tide;
            let filterYearType= this.Year;
            var imgsrc=''
             this.$gtag.event('illustration', {
                      'event_category': filterTideType,
                      'event_label': filterYearType,
                      'value': filterviewpoint
                    })
               console.log('Filers', filterviewpoint, filterTideType,filterYearType )
            if (this.content && this.content.imageSlider) {
                var filter= this.content.imageSlider.filter(item => (item.viewPoint.slug==filterviewpoint && item.year.slug==filterYearType && item.tide.slug==filterTideType) );
                console.log('Filer Vale', filter,  filterviewpoint, filterTideType,filterYearType )
                 this.ImageSrc= 'https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2F' + filter[0].image[0].file +'?alt=media'; 
                return  this.ImageSrc;
            }else return {};
      }
 
   },

   
  
  

};
</script>