<template>

<div>
  <b-navbar toggleable="lg" type="dark" variant="" class="stickynav" style="">
  
    <b-navbar-brand>
        <div class="fl brandLogo">
            <div class="fl county-logo"></div>
            <div class="fl atkins-logo"></div>
        </div>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto" right>
       <!-- <button @click="toggleImg">Toggle</button> -->
          
            <!-- <span class="nav-divi"> | </span> -->
            <b-nav-item
              v-for="(nav, index) in routerData"
              :key="`${$route.name}_nav_${index}`"
              :href="'#'+nav.link"
              >
              <!--div :class="nav.name === $route.name ? 'active' : ''" v-if="nav.subMenu!=true">
                {{ nav.name }}
              </div-->
              <div :class="nav.link === $route.path ? 'active' : ''" v-if="nav.subMenu!=true">
                {{ nav.name }}
              </div>
                  <b-nav-item-dropdown v-if="nav.subMenu==true">
                      <template #button-content>
                        <span> {{ nav.name }}</span>
                      </template>
                      <b-dropdown-item  v-for="(subItem, index) in nav.subMenuItems"
                        :key="`${$route.name}_subItem_${index}`"
                        :href="'#'+subItem.link">
                          {{ subItem.name }}
                      </b-dropdown-item>
                </b-nav-item-dropdown>
              </b-nav-item>
                <b-nav-item @click="() => toggleLangNew()" class="lang-button">
                <span v-if="language.name=='english'">Cymraeg</span>
                <span v-else>English</span>
              </b-nav-item>
              <!--  -->
                <!-- <b-nav-item-dropdown class="lang-button">
            
              <template #button-content>
                <span>{{ language.code }}</span>
              </template>
              <b-dropdown-item
                @click="() => toggleLang('english')"
                v-if="languageTranslations(currentLanguage.name)[0]"
                >{{
                  languageTranslations(currentLanguage.name)[0]
                }}</b-dropdown-item
              >
              <b-dropdown-item
                @click="() => toggleLang('welsh')"
                v-if="languageTranslations(currentLanguage.name)[1]"
                >{{
                  languageTranslations(currentLanguage.name)[1]
                }}</b-dropdown-item
              >
            </b-nav-item-dropdown> -->
              <!--  -->
           
             <b-nav-form right>
                <div class="fl search-holder" v-on:click="searchFunction()">
                  <div class="fl search-icon-holder" v-b-toggle.my-collapse><i class="fl search-icon" ></i><span class="fl mobile-txt">Search</span></div>
                </div>
                
            </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
 


</template>

<script>
import { forEach } from 'lodash';
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
const _ = require('lodash');
export default {
  name: "Header",
   data() {
    return {
     displayStyle:'none',
      searchTerms: ""
    };
  },
  props: {
    navbar: {
      type: Object,
      default() {
        return {
          title: "Navbar",
          home: { nav: "Home", route: "/" },
          pages: [{ nav: "Link", route: "#feedback", order: 1 }],
        };
      },
    },
    currentLanguage: {
      type: Object,
      default() {
        return {
        
          name: "english",
          code: "English",
        };
      },
    },
  },
  computed:{
     ...mapGetters(["language", "content","menu"]),
     routerData() {
       if (this.content && this.content.menu) {
         var newArr=[];
         var newArrPre=[];

          newArr= this.content.menu[0].menuItems.filter((item)=>{

          return item.show==true;

          
        });

   //   console.log("newArr is ", newArr)

/*       newArrPre.forEach(function (item, index) {
          if(item != "/")
          {
            console.log(item.l)
          }
            
        });*/

        // console.log("ALED TESTER")
        // console.log(newArr)
       
        return _.orderBy(newArr, 'orderNumber', 'asc');
       }else return {};
       
    },
    
  },
  mounted () {
    
  },
  methods: {
 
    ...mapActions(["toggleLanguage","finalStoreResults","toggleImage"]),
    toggleLang(lang) {
      this.toggleLanguage(lang);
    },
    toggleLangNew() {
      if(this.language.name=='english'){
           this.toggleLanguage('welsh');
      }else{
          this.toggleLanguage('english');
      }
     
    },
    toggleImg: function () {
            this.toggleImage();
    },

  
    languageTranslations(currentlang) {
      if (currentlang === "english") {
        return ["English", "Cymraeg"];
      } else if (currentlang === "welsh") {
        return ["English", "Cymraeg"];
      } else return [];
    },
 
  searchFunction(){
 
      const path = "/search/";
if (this.$route.path !== path) 
this.$router.push(path);
   },
  },
  
};
</script>








<style lang="scss">
.lang-button{
      background: #2a8476!important;
    border: 2px solid #2a8476;
    /* padding: 18px 24px; */
    height: 100%;
    border-radius: 8px;
    a{
      span{
      color:white!important;
      }
      &::after{
        color:white!important;
      }
    }
}
#my-collapse{
  .card-body{
    padding:0;
  }
   .card{
      top: -40px;
      background-color: transparent;
    }
}
.header-comp {
  padding:10px;
  margin-bottom: 150px;
  .active {
    border-bottom: 3px solid #1A544B;
    font-weight: bold;
  }
  .dropdown-toggle::after{
    vertical-align: 0.090em !important;
  }
  .dropdown-menu {
    border-color: white;
    background: white;
    min-width: 0 !important;

    a {
      color: #27606c !important;
    }
  }
  .navbar{
    display: inline-block !important;
    font-family: 'Museo';
  }
  .nav-link {
    color: #1A544B !important;
    padding:0;

    &:hover {
      text-decoration: none;
    }
  }

  .navbar-collapse {
    ul {
      li {
        list-style-type: none !important;
      }
    }
  }

  .navbar-toggler {
    border-color: white !important;
    border-radius: 50% !important;
    width: 56px !important;
    height: 56px !important;
     @media screen and (max-width: 500px) {
      padding: 0;
      padding-bottom: 2px;
      width: 35px !important;
      height: 35px !important;
    }
    @media screen and (max-width: 992px) {
      // display: none;
    }

    .navbar-toggler-icon {
       color: white;
      @media screen and (max-width: 500px) {
        width: 16px;
        height: 16px;
      }
    }
  }
  
  
}
.nav-link .active {
    border-bottom: 3px solid #1A544B;
    font-weight: bold;
  }
.navbar-dark .navbar-nav .nav-link{
  color: #1A544B !important;
  padding:0;
  padding-right: 15px !important; 
  padding-left: 15px !important;

    &:hover {
      text-decoration: none;
    }
  
}
.dropdown-menu{
    border-radius: 8px !important;
    left:-50px !important;
    margin-top: 10px !important;
    padding:10px !important;
    border: none !important;
    .dropdown-item{
      padding: 5px;
      border-bottom: 1px solid #fff;
      &:hover{
        background: none;
        border-bottom: 1px solid #60C4B7;
      }
      &.active{
        border-bottom: 1px solid #60C4B7;
      }
    }
  }
  .dropdown-menu:before {
       content: "";
       display: block;
       position: absolute;
       right: 42%;
       top: -10px;
       border-bottom: 10px solid #fff;
       border-left: 10px solid transparent;
       border-right: 10px solid transparent;
  }
  .dropdown-toggle::after{
    vertical-align: 1px !important;
    margin-left: 0.255em;
    border-top: 2px solid !important; 
    border-right: 2px solid !important;
    border-bottom: 0 !important;
    border-left: 0 solid transparent !important; 
    border-style: solid;
    content: '';
    display: inline-block;
    height: 8px;
    left: 5px;
    position: relative;
    top: -2px;
    vertical-align: top;
    width: 8px;
    transform: rotate(135deg);

  }
@media (min-width: 320px) and (max-width: 991px){
  .dropdown-toggle::after{
            width: 12px !important;
            height: 12px !important;
        }
}   

.stickynav
{
  position: fixed!important;
  top: 0!important;
  left: 0!important;
  right: 0!important;
  height: 100px!important;
  background-color: rgba(211,235,231,1)!important;
  z-index:1!important;

}
</style>