<template>
  <section class="site-cards">
    <div class="container">
      <div class="row">
        <div class="site-card-col col-sm-12 col-md-6 col-lg-3 col-xl-4" v-for="(items, index) in cardsData" :key="index">
            <div class="site-card">
              <div class="site-card-image" v-if="items.cardImage" >
                  <!-- :style="{ backgroundImage: `url(${items.cardImage[0].url})`}" -->
                <p><span>hover</span></p>
              </div>
              <div class="site-card-title white-text">
                <h4>{{ items.cardTitle }}</h4>
                <p v-html="items.cardDescription"></p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
  const _ = require('lodash');
export default {
  

  props: {
    data: Array,
    hoverText: String,
  },
   computed: {
    ...mapGetters(["content"]),
 cardsData() {
    var route=this.$route.path.split('/');
        var newArr=[]; 
        if (this.content && this.content.cardsContent) {
            newArr= this.content.cardsContent.filter((el)=> {
              if(el.linkedPage){
                  return el.linkedPage.pageSlug == route[1] ;
              }
                
            });
        return _.orderBy(newArr, 'orderNumber', 'asc');
        } else return {};
      
    },
   
},
};
</script>
<style lang="scss">
$cardDeepBlue: #536f90;
$cardDeepGrey: #727d93;
.site-cards {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .col {
      margin: 0 0 20px;
      float: none;
    }
  }
}
.site-cards {
  margin-bottom: 30px;
}

.site-card-col {
  margin-bottom: 20px;
}

.site-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  .site-card-image {
    position: relative;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    flex: 0 0 auto;
    &:after {
      content: "";
      display: block;
      padding-bottom: 75%;
    }
    p {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($color: #000000, $alpha: 0.5);
      text-align: center;
      opacity: 0;
      margin: 0 auto;
      transition: 0.3s ease opacity;

      span {
        border: solid white;
        border-radius: 50px;
        padding: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 60%;
        color: #fff;
        font-weight: 700;
      }
    }
  }
  .site-card-title {
    background: white;
    padding: 25px;
    font-size: 16px;
    flex: 1 0 auto;
    box-shadow: 0px 0px 23px 3px rgba(49, 156, 140, 0.16);
    border-radius: 8px;
    h4{
      font-size:25px!important;
      font-family: DM Serif Text;
      margin-bottom: 20px;
    }
    p{
       font-size:16px!important;
       ul{
         margin: 0;
         padding: 0;
         margin-left: 20px;
         li{
           padding: 3px 0;
         }
       }
    }
  }
  &:hover {
    text-decoration:none!important;
    .site-card-image p {
      opacity: 1;
    }
    .site-card-title {
       font-weight: 400;
    }
  }

}

</style>