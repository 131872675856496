<template>
  <main class="home">
    <Header />
    <LoadingComponent v-if="isLoading" />

    <!-- Content -->
    <div v-else class="LandingPage">
      <!-- Page content -->
      <section
        v-for="data in pageContentData"
        :key="data.id"
        :class="BackImage"
      >
        <b-container>
          <FAQAccordions
            v-if="data.templateType.slug === 'faqs'"
            :data="faqQuestions"
          />
          <Sections
            :sectionData="data"
            :sectionType="data.templateType.slug"
            v-else
          />
        </b-container>
      </section>
    </div>

    <!-- Footer section -->
    <section class="footer">
      <footer>
        <cookie-law theme="dark-lime"></cookie-law>
      </footer>
      <Footer />
    </section>
  </main>
</template>
<script>
  import Sections from '@/components/Section';
  import Header from '../components/Header';
  import Footer from '../components/Footer';
  const _ = require('lodash');
  import { mapGetters } from 'vuex';
  import FAQAccordions from '../components/FAQ/FAQAccordions.vue';
  import { flamelinkApp } from '@/services/FlamelinkSettings';

  export default {
    name: 'FAQ',
    components: {
      Header,
      Footer,
      Sections,
      FAQAccordions
    },
    data() {
      return {
        isLoading: false,
        faqQuestions: [],
        faqContent: []
      };
    },
    async mounted(){
      this.scrollToTop()
      await this.$gtag.query('event', 'screen_view', {
        app_name: 'MyApp',
        screen_name: 'FAQ',
      })
    },
    computed: {
      ...mapGetters(['language', 'content']),
      templateName() {
        return this.$route.params.slug;
      },
      pageContentData() {
        var route = this.$route.path.split('/');
        var newArr = [];
        if (this.content && this.content.pageContent) {
          newArr = this.content.pageContent.filter(el => {
            if (el.linkedPage.length > 0 && el._fl_meta_.status !== 'review') {
              if (el.linkedPage[0].pageSlug === route[1]) {
                // console.log("el is ", el)
                return el.linkedPage[0].pageSlug === route[1];
              }
            }
          });
          return _.orderBy(newArr, 'orderNumber', 'asc');
        } else return {};
      },
      currentLanguage() {
        if (this.language) {
          return this.language;
        } else return 'english';
      }
    },
    watch: {
      currentLanguage: {
        immediate: true,
        async handler(el) {
          const locale = el.name === 'english' ? 'en-US' : 'wales-england';
          const faqQuestions = await flamelinkApp(locale).content.get({
            schemaKey: 'faq',
            populate: true,
            filters: [['_fl_meta_.status', '==', 'publish']]
          });
          this.isLoading = false;

          this.faqQuestions = _.orderBy(
            Object.values(faqQuestions),
            'sortOrder',
            'asc'
          );
        },
      }
    },
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  };
</script>
