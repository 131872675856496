<template>
  <div id="app">
    <LoadingComponent v-if="loadingStatus" />
    <button
      v-if="language.name === 'english'"
      class="fl universal-access"
      @click="toggleImg"
    >
      <span class="fl icon-universal-access" />
      <span>Animation On/Off</span>
    </button>

    <button
      v-if="language.name === 'welsh'"
      class="fl universal-access"
      @click="toggleImg"
    >
      <span class="fl icon-universal-access" />
      <span>Trowch animeiddiadau ymlaen/i ffwrdd</span>
    </button>
    <router-view />
  </div>
</template>

<script>
  import apiEN from '@/services/FlamelinkData.js';
  import apiWL from '@/services/FlamelinkDataWL.js';
  import { mapActions, mapGetters } from 'vuex';
  import LoadingComponent from './components/LoadingComponent.vue';
  import Footer from './components/Footer.vue';
  import Header from './components/Header.vue';
  export default {
    name: 'App',
    async mounted() {
      await this.getAllDataEN();
      await this.getAllDataWL();
      // await this.getAllData()
      // console.log('app mounted')
    },
    computed: {
      ...mapGetters(['loadingStatus', 'language'])
    },
    methods: {
      ...mapActions([
        'storeResultsEN',
        'storeResultsWL',
        'storeLoadingStatus',
        'toggleImage'
      ]),
      toggleImg() {
        this.toggleImage();
      },
      getAllDataEN() {
        apiEN
          .getAllDataForFlamelink()
          .then(response => {
            this.storeResultsEN(response);
            this.storeLoadingStatus(false);
          })
          .catch(error => {
            console.log(error);
          });
      },
      getAllDataWL() {
        apiWL
          .getAllDataForFlamelink()
          .then(response => {
            this.storeResultsWL(response);
            this.storeLoadingStatus(false);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    components: { LoadingComponent, Footer, Header }
  };
</script>

<style lang="scss">
  // @import "assets/scss/main.scss";
</style>
