<template>
  <!--  @click="openUrl(slug)"-->
  <div class="accordion-card" header-tag="header" no-body role="tab" :id="slug">
    <!--    <p>{{slug}}</p>-->

    <div
      tabindex="0"
      block
      v-b-toggle="'accordion-accordiondata_' + id"
      class="accordion-header"
    >
      <div class="accordion-heading">
        <div class="title">
          <h5 class="h5">
            {{ question }}
          </h5>
        </div>

        <div class="accordionIcon">
          <div class="ShowClose">
            <i class="fas fa-chevron-down"></i>
          </div>
        </div>
      </div>
    </div>

    <b-collapse
      :id="'accordion-accordiondata_' + id"
      :accordion="'accordiondata-accordion'"
      class="accordion-body content"
      tabindex="0"
    >
      <div class="textArea">
        <div sm="12" md="10" lg="6" class="answerText">
          <p v-html="answer" />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
  export default {
    name: 'AccordionCard',
    props: {
      slug: {
        type: String,
        default: ''
      },
      question: {
        type: String,
        default: ''
      },
      answer: {
        type: String,
        default: ''
      },
      isExpanded: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number,
        default() {
          return 0;
        }
      }
    },
    methods: {
      // openUrl(slug){
      //   console.log("slugs is ", slug)
      //
      //   location.hash = slug;
      //   this.result = "Hash = "+location.hash;
      //
      //   const yOffset = -300;
      //   const element = document.getElementById(slug);
      //   const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      //
      //   window.scrollTo({top: y});
      //
      //   // const el = document.getElementById(slug);
      //   // el.scrollIntoView({behavior: "smooth"});
      //
      //   console.log("y is ", y)
      //   console.log("this.$route.hash is ", this.$route, y)
      //
      // },
      // getImgUrl(pet)
      // {
      //  // var images = require.context('../assets/images/Icons/', false, /\.png$/)
      //   return pet + ".png"
      // },
    }
  };
</script>

<style lang="scss">
  //@import "../Variables";

  $Gcc-Blue: #0054a4;
  $Gcc-DarkBlue: #05437d;
  $Gcc-Pale-BG: #edf1f6;
  $Gcc-White: #ffffff;

  .accordion-card {
    h5 {
      font-size: 1.5rem;
    }
    h5,
    p,
    a {
      font-family: 'DM Serif Text';
    }
    box-shadow: 0px 10px 14px rgba(0, 90, 132, 0.02),
      0px 2px 14px rgba(0, 90, 132, 0.06),
      inset 0px 0px 1px 1px rgba(0, 90, 132, 0.05) !important;
    .accordion-header {
      border-radius: 16px !important;
      &.not-collapsed {
        border-radius: 16px 16px 0 0 !important;
      }
    }
  }

  .accordion-card,
//.accordion-card:focus,
.accordion-card:active {
    padding: 0;
    margin: 1rem 0 0;
    background-color: transparent !important;
    box-shadow: none !important;

    .accordion-header,
    .accordion-header:active {
      margin: 0;
      background-color: $Gcc-White;
      padding: 10px 0;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      box-shadow: none;
      cursor: pointer;

      &.not-collapsed {
        .ShowClose {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }

        &:focus {
          outline: none;
        }
      }

      &.collapsed {
        &:focus {
          outline: 2px solid #0054a4;
        }
      }

      .accordion-heading {
        position: relative;
        padding: 10px 25px 4px;

        .desktopShow {
          display: block;

          @media screen and (max-width: 1024px) {
            display: none;
          }
        }

        .desktopHide {
          display: none;

          @media screen and (max-width: 1024px) {
            display: block;
          }
        }

        .textAreaHeader {
          flex: auto;
          padding: 0 10px;
          background-color: $Gcc-White;

          @media screen and (min-width: 1025px) {
            padding: 5px 20px;
          }
        }
      }

      .accordionIcon {
        padding-right: 1vw;
        font-size: 16px;
        color: #1a544b;
        text-align-last: center;
        align-self: center;
        position: absolute;
        right: 5px;
        top: 12px;
      }
    }

    .collapsible {
      .textArea {
        flex: auto;
        padding: 0 10px;
        background-color: $Gcc-White;

        @media screen and (min-width: 1025px) {
          padding: 5px 20px;
        }
      }
    }

    .content {
      padding: 0 18px;
      transition: max-height 0.2s ease-out;
      background-color: $Gcc-White;
      border-radius: 0 0 16px 16px !important;

      &.map {
        //overflow: scroll;
        //max-height: 12rem;

        &.collapse {
          &.show {
            &:focus {
              outline: 2px solid #0054a4;
            }
          }
        }
      }

      &.collapse {
        &.show {
          &:focus {
            outline: 2px solid #0054a4;
          }
        }
      }

      .textArea {
        flex: auto;
        padding: 10px;
        background-color: $Gcc-White;

        @media screen and (min-width: 1025px) {
          padding: 5px 20px;
        }
      }
    }

    .iconArea {
      width: auto;
      padding-right: 100%;
      //padding-left: 1vw;
      text-align-last: right;
      margin: auto;
      position: relative;
      //transform: translate(0px, -30px);
    }

    .answerText {
      margin: 10px 4% 3% 2%;
    }

    p {
      // color: $Gcc-Blue;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Museo';
      font-weight: 500;
    }

    a{
      //color: $Gcc-Blue;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Museo';
      font-weight: 500;
      text-decoration: underline;
    }
  }
</style>
