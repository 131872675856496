<template>
  <div class="title-text-comp">
    <h1 v-if="title" :class="smallTitle ? 'smallTitle' : ''" >{{ title }}</h1>
    <p class="mainText" v-if="text && main" v-html="text">
    
    </p>
    <p v-if="text && !main" v-html="text">
    </p>
   
    <p class="titlelink" v-if="button" >
      <button type="button" :class="buttonStyle"  v-if="button">
        <a :href="buttonLink">{{ buttonText }}</a>
      </button>
    </p>
    <p class="titlelink" v-if="link">
        <a :href="linkLink">{{ linkText }} &#x276F;</a>
    </p>
    
  </div>
</template>
buttonStyle
<script>
export default {
  name: "TitleText",
  props: {
    title: {
      type: String,
      default: " ",
    },
    text: {
      type: String,
      default: "",
    },
    buttonStyle: {
      type: String,
      default: "colour",
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonLink: {
      type: String,
      default: "",
    },
    link: {
      type: Boolean,
      default: false,
    },
    linkText: {
      type: String,
      default: "",
    },
    linkLink: {
      type: String,
      default: "",
    },
    main: {
      type: Boolean,
      default: false,
    },
    
    smallTitle: {
      type: Boolean,
      default: false,
    },
   
  },
};
</script>

<style lang="scss">
     
.title-text-comp {
  width: 100%;
  padding:  0;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  a{
  color:#2A8476 !important;
  text-decoration: underline !important;
  &:hover{
    text-decoration: none !important;
  }
}   

  h1 {
    font-size: 56px;
    margin-bottom: 20px;
    &.smallTitle {
      font-size: 32px;
    }

    @media screen and (max-width: 500px) {
      font-size: 24px;
    }

    @media screen and (max-width: 769px) {
      font-size: 30px;
    }
  }
  p {
    &.mainText {
      font-size: 16px;
      font-weight:400;

      @media screen and (max-width: 500px) {
        font-size: 16px;
      }

      @media screen and (max-width: 769px) {
        font-size: 20px;
      }
    }
  }
  .titlelink {

    button {
      padding: 18px 24px;
      height: 100%;
      border-radius: 8px;
      font-size: 16px;
      border:none;
      &.colour{
        background:#2A8476!important;
        border: 2px solid #2A8476;
        a{
          color:white !important;
           text-decoration: none !important;
          &:hover{
            text-decoration: none !important;
          }
          }
        &:hover{
          background:transparent!important;
          a{
            color:#2A8476 !important;
             text-decoration: none !important;
            &:hover{
            text-decoration: none !important;
          }
          }
        }
      }
      &.transparent
      {
        
       a{
          color: #2A8476 !important;
         text-decoration: none !important;
        }
      border: 2px solid #2A8476;
      background: transparent!important;;
      &:hover{
          background:#2A8476!important;
          a{
            color:#fff !important;
             text-decoration: none !important;
            &:hover{
            text-decoration: none !important;
          }
          }
        }
      }

    }

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
}
</style>