  <template>
    <main class="home">
 
      <!-- HEADER TOP MENU BAR -->
        <Header></Header>
      <!-- <Header :currentLanguage="currentLanguage"  :navbar="bannerData.navs"></Header> -->
      <!-- Rest of the HOME PAGE STARTS -->
     <!-- {{illustrationData}}
       <lottie-player src="https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2FSurfer_BodyMovin%20(2).json?alt=media" background="transparent"  speed="1"  style="width: 100%; height: 100%;" loop autoplay></lottie-player>
       <lottie-player src="https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2FSeal_Bodymovin.json?alt=media" background="transparent"  speed="1"  style="width: 100%; height: 100%;" loop autoplay></lottie-player>
     <lottie-player src="https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2FSeal_v2.json?alt=media" background="transparent"  speed="1"  style="width: 100%; height: 100%;" loop autoplay></lottie-player> 
 -->

        <div class="LandingPage">
        <div class="container">
          <!-- SECTION 1 -->  
          <section  v-for="(data,index) in homePageContentData" :key="index" :class="data.templateType.slug+'-section'" > 
            <b-container>
              <Sections :sectionData="data" :sectionType="data.templateType.slug" />
            </b-container>
          </section>
          </div>
           </div>
         
      <!-- footer section -->
            <section class="footer">
              <footer>
                <cookie-law theme="dark-lime"></cookie-law>
              </footer>
              <Footer/>
            </section>
    </main>
</template>

<script>
// gtag('event', 'page_view', {
//   page_title: 'Home',
//   page_location: '/',
//   page_path: '/',
//   send_to: 'G-4YDZT6RTPE'
// })

import Header from "../components/Header";
import Footer from "../components/Footer";
import Sections from "../components/Section";
import CookieLaw from "vue-cookie-law";
const _ = require('lodash');
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Sections,
    Header,
    Footer,
  },
  data(){
    return{
      newData:[]
    }
  },
  computed: {
    ...mapGetters(["language", "content"]),
    illustrationData(){
      if(this.content.illustration){
        console.log('IILLUS',this.content.illustration)
        
      }
      return 'https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2FSurfer_BodyMovin.json?alt=media&token=9b0eddc8-7a25-43ee-b822-f3c32bed8c20'
    },
    homePageContentData() {
        var route=this.$route.path.split('/');
        var newArr=[]; 
        if (this.content && this.content.pageContent) {
            newArr= this.content.pageContent.filter((el)=> {
              if(el.linkedPage.length>0 && el._fl_meta_.status!='review'){
                  return el.linkedPage[0].pageSlug == '' ;
              }
                
            });
            // console.log('!!!EELLL!!',_.orderBy(newArr, 'orderNumber', 'asc'))
        return _.orderBy(newArr, 'orderNumber', 'asc');
        } else return {

        };
    },
   
    // currentLanguage() {
    //   if (this.content.language) {
    //     return this.language;
    //   } else return "english";
    // },
  },
  // mounted() {
  //   this.$flamelinkApp.content.get("menu", (error, homeData) => {
  //     if (error) {
  //       this.pageTitle = "Error fetching Home page data";
  //       return console.error(error);
  //     }
  //     this.newData=homeData
  //     console.log('HHH',homeData)
  //     // this.pageTitle = get(homeData, "pageTitle", "");
  //     // this.pageBody = get(homeData, "pageBody", "");
  //   });
  // }

  async  mounted() {
    this.scrollToTop()
  //  await this.$gtag.query('event', 'screen_view', {
  //       app_name: 'MyApp',
  //       screen_name: 'Home page',
  //     })
  },

 methods: { 

      scrollToTop() {
          window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
 
      }
  },
};
</script>
