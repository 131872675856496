import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import getRouter from "./router";
import {FlamelinkPlugin} from "./services/FlamelinkSettings";
Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.config.silent = true
import VueGtag from "vue-gtag";



import store from "./store";

// import router from './router';
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
 
// Vue.use(LottieAnimation);
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// const analytics = firebase.analytics();
// Vue.use(analytics);

import "./assets/scss/main.scss";
Vue.use(Vuex);
Vue.use(FlamelinkPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
getRouter()
  .then(router => {
          Vue.use(VueGtag, {
            config: {   id: "G-CNJ2LKDZTM" },
            appName: 'My Newgale application',
            pageTrackerTemplate(to){
              return{
                page_title:to.path,
                page_path:to.fullPath
              }
            }
            // pageTrackerScreenviewEnabled: true,
            // params: {
            //   send_page_view: true
            // },
          }, router);
      new Vue({
        router,
        store,
        render: h => h(App),
      }).$mount('#app')
}).catch(console.error);