<template>
  <section class="site-cards">
    <!-- <LoadingComponent v-if="isLoading" /> -->
    <!-- FAQs -->
    <div v-for="(el, i) in faqQuestions" :key="el.id">
      <h2
        style="font-family: 'DM Serif Text'"
        :class="{ 'pt-5': Boolean(i) }"
        v-if="renderTitle(el) && showTitleFor.includes(el.id)"
      >
        {{ el.section.title }}
      </h2>

      <AccordionCard
        :question="el.question"
        :answer="el.answer"
        :slug="'faq/post#' + el.slug"
        :id="el.id"
      />
    </div>

<!--    todo: comment back to "show more/less" buttons-->
<!--    <div class="title-text-comp pt-5 d-flex justify-content-center">
      <button type="button" class="fl tbl-btn" @click="viewMore" v-if="!end">
        <span>Show more questions</span>
      </button>
      <button
        type="button"
        class="fl tbl-btn"
        @click="viewLess"
        v-if="!beginning"
      >
        <span>Show fewer questions</span>
      </button>
    </div>-->

  </section>
</template>

<script>
  import AccordionCard from './AccordionCard.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'FAQAccordions',
    components: {
      AccordionCard
    },
    props: {
      data: { type: Array, default: () => [] }
    },
    data() {
      return {
        isLoading: true,
        categories: [],
        showTitleFor: [],
        //toShow: 8,
        toShow: 100,
        currentShown: 0,
        beginning: true,
        end: false
      };
    },
    mounted() {
      this.currentShown = this.toShow;
    },
    computed: {
      ...mapGetters(['content']),
      faqQuestions() {
        var newArr = [];
        newArr = this.data.filter(el => {
          if (el._fl_meta_.status === 'publish') {
            return el;
          }
        });
        return newArr.slice(0, this.currentShown);
      }
     /* faqQuestions() {
        return this.data.slice(0, this.currentShown);
      }*/
    },
    methods: {
      renderTitle(el) {
        if (!this.categories.includes(el.section.title)) {
          this.categories = [...this.categories, el.section.title];
          this.showTitleFor = [...this.showTitleFor, el.id];
        }
        return true;
      },
      viewMore() {
        if (this.currentShown < this.data.length) {
          // first click
          if (this.currentShown === this.toShow) this.beginning = false;
          if (this.currentShown + this.toShow >= this.data.length) {
            this.currentShown = this.data.length;
            return (this.end = true);
          } else {
            this.currentShown = this.currentShown + this.toShow;
          }
        }
      },
      viewLess() {
        if (this.currentShown > this.toShow) {
          // first click
          if (this.currentShown === this.data.length) this.end = false;
          if (this.currentShown - this.toShow <= this.toShow) {
            this.currentShown = this.toShow;
            return (this.beginning = true);
          } else {
            this.currentShown = this.currentShown - this.toShow;
          }
        }
      }
    }
  };
</script>
<style lang="scss">
  $cardDeepBlue: #536f90;
  $cardDeepGrey: #727d93;
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #1a544b !important;
    border-color: #1a544b !important;
  }
  .radio-buttons .container {
    max-width: 100% !important;
  }
  .page-item .page-link {
    color: #1a544b;
  }
  .ToolBar label {
    color: #1a544b;
  }
  body .LandingPage section.site-cards {
    padding-top: 0 !important;
  }
  .site-cards {
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .col {
        margin: 0 0 20px;
        float: none;
      }
    }
  }
  .image-bottom {
    position: relative;
    img {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
  .text-bottom {
    position: relative;
    p {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
  }
  .site-cards {
    margin-bottom: 30px;
  }

  .site-card-col {
    margin-bottom: 30px;
  }

  .site-card-news {
    display: flex;
    flex-direction: column;
    height: 100%;

    .site-card-title {
      height: 270px;
      background: white;
      padding: 30px;
      font-size: 16px;
      flex: 1 0 auto;
      overflow: hidden;

      h4 {
        color: #1a544b;
        font-size: 24px;
        font-family: DM Serif Text;
        line-height: 30px;
      }
      p {
        font-size: 16px;
      }
    }
    &:hover {
      text-decoration: none !important;
      .site-card-image p {
        opacity: 1;
      }
      .site-card-title {
        font-weight: 400;
      }
    }
  }
  .text-bottom {
    height: 80px;
    background: #fff;
    padding: 0 30px;
    span {
      padding: 0 3px;
      &.divider {
        color: #000 !important;
      }
    }
  }
  .site-card-col a {
    color: #2a8476;
    &:hover {
      color: #2a8476;
    }
  }
</style>
