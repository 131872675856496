import axios from 'axios';
import { AppEN, AppWL } from '../services/FlamelinkSettings';

const MenuBar = 'menuBar';
const PageContent = 'pageContent';
const Routes = 'routes';
const TemplatesType = 'typesOfTemplates';
const Timeline = 'timeline';
const Footer = 'footer';
const Cards = 'cards';
const Documents = 'documents';
const Blog = 'blog';
const BlogCategory = 'blogCategory';
const Illustration = 'illustration';
const ContactForm = 'contactForm';
const DocumentCategory = 'documentCategoryNew';
const TideType = 'tideType';
const ViewPointType = 'viewPointType';
const YearType = 'yearType';
const ImageSlider = 'imageSlider';
const SlickSlider = 'slickSlider';
const FourSection = 'fourSection';
const FAQ = 'faq';
const FAQCategory = 'faqCategory';

export default {
  async getSliderData() {
    var theDataString = [];

    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: SlickSlider,
        populate: true
      });
      if (dataPayload == null) {
        theDataString.push('null');
      } else if (dataPayload == undefined) {
        theDataString.push('undefined');
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getImageSlider() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: ImageSlider,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        //console.log('tide',theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getYearType() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: YearType,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        //  console.log('tide',theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getTideType() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: TideType,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        // console.log('tide',theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getViewPointType() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: ViewPointType,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        // console.log('ViewPoint',theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getDocumentCategory() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: DocumentCategory,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        // console.log('doccat',theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getContactForm() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: ContactForm,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        //  console.log('CONTACT FORM',theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getIllustration() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: Illustration,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        //  console.log('DDD',theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getBlogCategory() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: BlogCategory,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });

        // console.log('blogcat',theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getBlog() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: Blog,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getDocuments() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: Documents,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        // console.log("document", theDataString);
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getMenuBar() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: MenuBar,
        populate: true
      });
     // console.log(dataPayload)
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          if (val._fl_meta_.status === 'publish') {
            theDataString.push(val);
          }
        });

       /*  if (theDataString._fl_meta_.status === 'publish') {

          return theDataString;
         }*/
       // console.log(theDataString)
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getCardsData() {
    var theDataString = [];

    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: Cards,
        populate: true
      });
      if (dataPayload == null) {
        theDataString.push('null');
      } else if (dataPayload == undefined) {
        theDataString.push('undefined');
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getFourSectionData() {
    var theDataString = [];

    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: FourSection,
        populate: true
      });
      if (dataPayload == null) {
        theDataString.push('null');
      } else if (dataPayload == undefined) {
        theDataString.push('undefined');
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getPageContent() {
    var theDataString = [];

    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: PageContent,
        populate: true
      });
      if (dataPayload == null) {
        theDataString.push('null');
      } else if (dataPayload == undefined) {
        theDataString.push('undefined');
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getRoutes() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({ schemaKey: Routes });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getTemplatesType() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({ schemaKey: TemplatesType });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },

  async getTimeline() {
    var theDataString = [];

    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: Timeline,
        populate: true
      });
      if (dataPayload == null) {
        theDataString.push('null');
      } else if (dataPayload == undefined) {
        theDataString.push('undefined');
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },
  async getfooterdata() {
    var theDataString = [];

    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: Footer,
        populate: true
      });
      // console.log('All of your ' , dataPayload)

      if (dataPayload == null) {
        theDataString.push('null');
      } else if (dataPayload == undefined) {
        theDataString.push('undefined');
      } else {
        Object.values(dataPayload).forEach(val => {
          theDataString.push(val);
        });
        //theDataString =dataPayload;
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },

  async getAllFAQ() {
    var theDataString = [];

    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: FAQ,
        populate: true
      });

      if (dataPayload == null) {
        theDataString.push('null');
      } else if (dataPayload == undefined) {
        theDataString.push('undefined');
      } else {
        Object.values(dataPayload).forEach(val => {
          console.log('welsh faq ', val);
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },

  async getFaqCategories() {
    var theDataString = [];
    try {
      const dataPayload = await AppWL.content.get({
        schemaKey: FAQCategory,
        populate: true
      });
      if (dataPayload == null) {
        theDataString = 'null';
      } else if (dataPayload == undefined) {
        theDataString = 'undefined';
      } else {
        Object.values(dataPayload).forEach(val => {
          console.log('welsh categories ', val);
          theDataString.push(val);
        });
        return theDataString;
      }
      return theDataString;
    } catch (error) {
      console.log('ERROR', error);
    }
  },

  /**
   * Get All Request
   */

  getAllDataForFlamelink() {
    return axios
      .all([
        this.getFourSectionData(),
        this.getImageSlider(),
        this.getYearType(),
        this.getTideType(),
        this.getViewPointType(),
        this.getDocumentCategory(),
        this.getContactForm(),
        this.getIllustration(),
        this.getBlogCategory(),
        this.getBlog(),
        this.getMenuBar(),
        this.getPageContent(),
        this.getRoutes(),
        this.getTemplatesType(),
        this.getTimeline(),
        this.getfooterdata(),
        this.getCardsData(),
        this.getDocuments(),
        this.getSliderData()
        // this.getAllFAQ(),
        // this.getFaqCategories()
      ])
      .then(
        axios.spread(function (
          fourSection,
          imageSlider,
          yearType,
          tidetype,
          viewpointtype,
          docCat,
          cForm,
          illustration,
          blogCat,
          blog,
          menu,
          pageContent,
          routes,
          templatesType,
          timeline,
          footer,
          cardsContent,
          libData,
          sliderData
          // faq,
          // faqCat
        ) {
          return {
            fourSection,
            imageSlider,
            yearType,
            tidetype,
            viewpointtype,
            docCat,
            cForm,
            illustration,
            blogCat,
            blog,
            menu,
            pageContent,
            routes,
            templatesType,
            timeline,
            footer,
            cardsContent,
            libData,
            sliderData
            // faq,
            // faqCat
          };
        })
      );
  }
};
