<template>
  <div :class="sectionType">
    <!-- text-center -->
    <section class="single-center-column" v-if="sectionType === 'text-center'">
      <div class="container">
        <div
          class="row row-cols-12 w-100 justify-content-center align-self-center"
        >
          <div class="col mx-auto text-center">
            <div>
              <TitleText
                :main="true"
                :smallTitle="sectionData.smallTitle"
                :title="sectionData.title"
                :text="sectionData.desc"
                :button="sectionData.button"
                :buttonStyle="sectionData.buttonStyle"
                :buttonText="sectionData.buttonText"
                :buttonLink="sectionData.buttonLink"
                :link="sectionData.link"
                :linkText="sectionData.linkText"
                :linkLink="sectionData.linkText"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- text Image -->
    <section
      class="single-center-column mob-divider"
      v-if="sectionType === 'text-image'"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-sm-12 col-md-6 col-lg-6 text-image-dimension v-center-txt"
          >
            <TitleText
              :main="true"
              :smallTitle="sectionData.smallTitle"
              :title="sectionData.title"
              :text="sectionData.desc"
              :button="sectionData.button"
              :buttonStyle="sectionData.buttonStyle"
              :buttonText="sectionData.buttonText"
              :buttonLink="sectionData.buttonLink"
              :link="sectionData.link"
              :linkText="sectionData.linkText"
              :linkLink="sectionData.linkLink"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div
              v-if="sectionData.illustration && !showImage"
              class="illustration-display-center"
            >
              <lottie-player
                :src="imgPath(sectionData.illustration[0].file)"
                background="transparent"
                speed="1"
                style="width: 100%; height: auto"
                loop
                autoplay
              ></lottie-player>
            </div>
            <img
              :src="imgPath(sectionData.imageDeck[0].image[0].file)"
              :alt="sectionData.imageAltText"
              v-if="sectionData.imageDeck && showImage"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- IMage Text -->
    <section
      class="single-center-column mob-divider"
      v-if="sectionType === 'image-text'"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div
              v-if="sectionData.illustration && !showImage"
              class="illustration-display-center"
            >
              <lottie-player
                :src="imgPath(sectionData.illustration[0].file)"
                background="transparent"
                speed="1"
                style="width: 100%; height: auto"
                loop
                autoplay
              ></lottie-player>
            </div>
            <img
              :src="imgPath(sectionData.imageDeck[0].image[0].file)"
              :alt="sectionData.imageAltText"
              v-if="sectionData.imageDeck && showImage"
            />
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-6 image-text-dimension v-center-txt"
          >
            <TitleText
              :main="true"
              :smallTitle="sectionData.smallTitle"
              :title="sectionData.title"
              :text="sectionData.desc"
              :button="sectionData.button"
              :buttonStyle="sectionData.buttonStyle"
              :buttonText="sectionData.buttonText"
              :buttonLink="sectionData.buttonLink"
              :link="sectionData.link"
              :linkText="sectionData.linkText"
              :linkLink="sectionData.linkLink"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- Double column Text -->
    <section class="double-text-column" v-if="sectionType === 'text-text'">
      <div class="container">
        <h1 class="doubletexttitle">{{ sectionData.title }}</h1>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 dbl-col-txtPad">
            <TitleText
              :main="true"
              :smallTitle="sectionData.smallTitle"
              :text="sectionData.desc"
              :button="sectionData.button"
              :buttonStyle="sectionData.buttonStyle"
              :buttonText="sectionData.buttonText"
              :buttonLink="sectionData.buttonLink"
              :link="sectionData.link"
              :linkText="sectionData.linkText"
              :linkLink="sectionData.linkLink"
            />
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-6 image-text-dimension dbl-col-txtPadbothside"
          >
            <TitleText
              :main="true"
              :smallTitle="sectionData.smallTitle"
              :text="sectionData.desc2"
              :button="sectionData.button"
              :buttonStyle="sectionData.buttonStyle"
              :buttonText="sectionData.buttonText"
              :buttonLink="sectionData.buttonLink"
              :link="sectionData.link"
              :linkText="sectionData.linkText"
              :linkLink="sectionData.linkLink"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- Text only -->

    <section class="left-text-column" v-if="sectionType === 'text-left'">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-10">
            <TitleText
              :main="true"
              :smallTitle="sectionData.smallTitle"
              :title="sectionData.title"
              :text="sectionData.desc"
              :button="sectionData.button"
              :buttonStyle="sectionData.buttonStyle"
              :buttonText="sectionData.buttonText"
              :buttonLink="sectionData.buttonLink"
              :link="sectionData.link"
              :linkText="sectionData.linkText"
              :linkLink="sectionData.linkLink"
            />
          </div>
        </div>
      </div>
    </section>

    <section
      class="single-center-column"
      v-if="sectionType === 'image-right-text'"
    >
      <!-- THIS IS PRESS RELEASE SECTION ADD YOUR HTML CSS THEN I CAN MAKE IT WORK WITH DATA -->
      <div class="container">
        <div class="row">
          <div class="col-md-8 img-overlay-wrap">
            <img
              v-if="sectionData.imageDeck"
              :src="imgPath(sectionData.imageDeck[0].image[0].file)"
              :alt="sectionData.imageAltText"
            />
          </div>
          <div class="col-md-4 image-text-dimension">
            <div class="card overlap-section">
              <TitleText
                :main="true"
                :smallTitle="sectionData.smallTitle"
                :title="sectionData.title"
                :text="newsdata.blogTitle"
                :button="sectionData.button"
                :buttonStyle="sectionData.buttonStyle"
                :buttonText="sectionData.buttonText"
                :buttonLink="urlPath(newsdata.slug)"
                :link="sectionData.link"
                :linkText="sectionData.linkText"
                :linkLink="sectionData.linkLink"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="single-center-column" v-if="sectionType === 'image-center'">
      <div class="container">
        <div
          class="row row-cols-12 w-100 justify-content-center align-self-center"
        >
          <div class="col mx-auto text-center">
            <div v-if="sectionData.ifMobile == true" class="img-center-wrap">
              <img
                class="d-none d-sm-none d-md-block"
                v-if="sectionData.imageDeck"
                :src="imgPath(sectionData.imageDeck[0].image[0].file)"
                :alt="sectionData.imageAltText"
              />
              <img
                class="d-block d-sm-block d-md-none"
                v-if="sectionData.ifMobile == true"
                :src="imgPath(sectionData.imageMobile[0].file)"
                :alt="sectionData.imageAltText"
              />
            </div>
            <div v-if="sectionData.ifMobile == false" class="centered-img">
              <div
                class="illustration-display-center"
                v-if="sectionData.illustration && !showImage"
              >
                <lottie-player
                  :src="imgPath(sectionData.illustration[0].file)"
                  background="transparent"
                  speed="1"
                  style="width: 50%; height: 100%"
                  loop
                  autoplay
                ></lottie-player>
              </div>
              <img
                :src="imgPath(sectionData.imageDeck[0].image[0].file)"
                :alt="sectionData.imageAltText"
                v-if="sectionData.imageDeck && showImage"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="sectionType === 'timeline'">
      <TimelineTemplate />
    </section>

    <section v-if="sectionType === 'box'">
      <Card />
    </section>
    <section v-if="sectionType === 'four-section'">
      <div class="container">
        <div
          class="row row-cols-12 w-100 justify-content-center align-self-center"
        >
          <div class="col mx-auto text-center">
            <TitleText
              :main="true"
              :smallTitle="sectionData.smallTitle"
              :title="sectionData.title"
              :text="sectionData.desc"
              :button="sectionData.button"
              :buttonStyle="sectionData.buttonStyle"
              :buttonText="sectionData.buttonText"
              :buttonLink="sectionData.buttonLink"
              :link="sectionData.link"
              :linkText="sectionData.linkText"
              :linkLink="sectionData.linkLink"
            />
          </div>
        </div>
        <div class="row">
          <FourSection />
        </div>
      </div>
    </section>
    <section v-if="sectionType === 'slickSlider'">
      <b-container>
        <SlickSlider />
      </b-container>
    </section>

    <section v-if="sectionType === 'blogCards'">
      <BlogCards :data="blogData" />
    </section>

    <section v-if="sectionType === 'documentLibrary'">
      <DLib :data="documentsData" />
    </section>

    <section v-if="sectionType === 'imageSlider'">
      <div class="container">
        <div class="row">
          <ImageSlider
            :title="sectionData.title"
            :text="sectionData.desc"
            :desc1="sectionData.desc1"
            :desc2="sectionData.desc2"
            :desc3="sectionData.desc3"
          />
        </div>
      </div>
    </section>

    <section v-if="sectionType === 'faqs'">
      <FAQAccordions />
    </section>
  </div>
</template>

<script>
  import TitleText from '../components/TitleText';
  import TimelineTemplate from '../components/TimelineTemplate';
  import Card from '../components/Cards';
  import FourSection from '../components/FourSection';
  import SlickSlider from '../components/SlickSlider';
  import BlogCards from '../components/NewsCards';
  import DLib from '../components/DocumentLibrary';
  import FAQAccordions from './FAQ/FAQAccordions.vue';
  import ImageSlider from '../components/ImageSlider';
  import { mapGetters } from 'vuex';

  const _ = require('lodash');
  export default {
    name: 'Section',
    components: {
      FourSection,
      TitleText,
      TimelineTemplate,
      Card,
      SlickSlider,
      BlogCards,
      DLib,
      ImageSlider,
      FAQAccordions
    },
    computed: {
      ...mapGetters(['content', 'showImage']),
      documentsData() {
        var route = this.$route.path.split('/');
        var newArr = [];
        if (this.content && this.content.libData) {
          var filteredForLive = this.content.libData.filter(el => {
            if (el._fl_meta_.status != 'review') {
              return el;
            }
          });
          return _.orderBy(filteredForLive, 'orderNumber', 'asc');
        } else return {};
      },
      /* blogData(){
        var newArr=[];
         if (this.content && this.content.blog) {
           newArr= _.orderBy(this.content.blog, 'date', 'asc');
           console.log("newsnewArr",newArr);
           return newArr
         } else return {};
     },*/
      // faqCatData() {
      //   var newArr = [];
      //   if (this.content && this.content.faqCat) {
      //     var filteredForLive = this.content.faqCat.filter(el => {
      //       if (el._fl_meta_.status != 'review') {
      //         return el;
      //       }
      //       // if(el.liveStatus === "live" || el.liveStatus === "Live")
      //       // {
      //       //   if (el._fl_meta_.status != 'review') {
      //       //     return el;
      //       //   }
      //       // }
      //     });
      //     var sortArr = _.sortBy(filteredForLive, function (dateObj) {
      //       return new Date(dateObj.date);
      //     }).reverse();
      //     newArr = _.orderBy(sortArr, 'sortOrder', 'asc');
      //     // console.log('NEW', newArr)
      //     // console.log('',)
      //     return newArr;
      //   } else return {};
      // },
      // faqData() {
      //   var newArr = [];
      //   if (this.content && this.content.faq) {
      //     var filteredForLive = this.content.faq.filter(el => {
      //       if (el._fl_meta_.status != 'review') {
      //         return el;
      //       }
      //       // if(el.liveStatus === "live" || el.liveStatus === "Live")
      //       // {
      //       //   if (el._fl_meta_.status != 'review') {
      //       //     return el;
      //       //   }
      //       // }
      //     });
      //     var sortArr = _.sortBy(filteredForLive, function (dateObj) {
      //       return new Date(dateObj.date);
      //     }).reverse();
      //     newArr = _.orderBy(sortArr, 'sortOrder', 'asc');
      //     // console.log('NEW', newArr)
      //     // console.log('',)
      //     return newArr;
      //   } else return {};
      // },
      blogData() {
        var newArr = [];
        if (this.content && this.content.blog) {
          var filteredForLive = this.content.blog.filter(el => {
            if (el._fl_meta_.status != 'review') {
              return el;
            }
          });
          var sortArr = _.sortBy(filteredForLive, function (dateObj) {
            return new Date(dateObj.date);
          }).reverse();
          newArr = _.orderBy(sortArr, 'orderNumber', 'asc');
          // console.log('NEW', newArr)
          // console.log('',)
          return newArr;
        } else return {};
      },
      newsdata() {
        var newArr = [];
        if (this.content && this.content.blog) {
          var filteredForLive = this.content.blog.filter(el => {
            if (el._fl_meta_.status != 'review') {
              return el;
            }
          });
          var sortArr = _.sortBy(filteredForLive, function (dateObj) {
            //  var sortArr = _.sortBy(this.content.blog, function(dateObj) {
            return new Date(dateObj.date);
          }).reverse();
          var currentdate = sortArr[0].date;
          var curdateArr = sortArr.filter(item => {
            return item.date == currentdate;
          });
          newArr = _.orderBy(curdateArr, 'orderNumber', 'asc');
          return newArr[0];
        } else return {};
      },
      updateSite() {
        // console.log("Update Site");
        return -1;
      }
    },
    props: {
      sectionData: {
        type: Object
      },
      sectionType: {
        type: String
      },
      docData: {
        type: Array
      }
    },
    data() {
      return {};
    },
    created: function () {},
    methods: {
      imgPath(imageName) {
        return (
          'https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2F' +
          imageName +
          '?alt=media'
        );
      },
      urlPath(url) {
        return '#/blog/' + url;
      },

      ForcesUpdateComponent() {
        // your code
        this.$forceUpdate();
        // your code
      }
    }
  };
</script>
<style lang="scss">
  .doubletexttitle {
    font-size: 32px;
  }

  img {
    width: 100%;
  }

  ol {
    margin-bottom: 50px !important;
  }

  ol li {
    text-align: left;
  }

  #lottie-controls {
    display: none;
  }

  .illustration-display-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
