<template>
  <section class="site-cards">
    <div class="container">
        <div class="row">{{valueIs}}
          <div class="dropdown" v-if="dropdownData.length>0" id="blogCategoryDropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Category: <span class="selection"></span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="blogCategoryDropdownOptions">
                    <a class="dropdown-item" href="#" v-for="(items, index) in dropdownData" :key="index" :id="items.title">{{items.title}}</a>
                </div>
                
            </div>
        </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import Store from '@/store/index.js'
const _ = require('lodash');
const $ = require('jquery');
export default {

  props: {
    valueIs: String,
  },
   computed: {
    ...mapGetters(["blogCategory"]),
    dropdownData() {
        if (this.content && this.content.blogCategory) {
            return this.content.blogCategory
        } else return {};
    },
   
},
mounted(){
        $(document).ready(() =>{ 
            var self = this;
            console.log('DDDD',$('#blogCategoryDropdown').dropdown())
            $("#blogCategoryDropdown").click(function(){alert('test1'+ $(this).find('.selection').text($('#blogCategoryDropdownOptions a').text()))
                 $(this).find('.selection').text()
            });
            $("#blogCategoryDropdownOptions a").click(function(){alert('test')

                $('#blogCategoryDropdownOptions a').parent().find('.selection').text($('#blogCategoryDropdownOptions a').text());
                $(this).parent(".dropdown").find('.selection').val($(this).text());

            });
            // $(document).on('change','', (event) =>{
            //     var content=[]
            //     var filterValue=$("input[name='options']:checked")
            //     var checkedElements = Array.prototype.map.call(filterValue, function (el, i) {
            //             return content.push(el.id)
            //     });
            //     Store.dispatch('SET_BLOG_CAT',content)
            // })
        })
    },
};

</script>
<style lang="scss">
</style>
