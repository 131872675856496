<template>
   <section class="container ">
  <div class="container doc-library">
    <form class="w100" action="." @submit.prevent="submitdocSearch">
    <div class="row frm-wrap">
        <div class="col col-md-4">
          <label for="Search" v-if="language.name === 'english'">Search</label>
                <label for="Search" v-if="language.name === 'welsh'">Chwilio</label>
          <input class="searchBox" type="text" id="Search" v-model="searchQuery" v-on:keyup="keymonitor()" v-if="language.name === 'english'" placeholder="Enter your search term">
     
      <input class="searchBox" type="text" id="Search" v-model="searchQuery" v-on:keyup="keymonitor()" v-if="language.name === 'welsh'" placeholder="Rhowch eich term chwilio">
        </div> 
        <div class="col col-md-4">
          <label for="FileType" v-if="language.name === 'english'">Filter By File Type</label>
          <label for="FileType" v-if="language.name === 'welsh'">Hidlo fesul math o ffeil</label>
          <select id="FileType" name="FileType" v-model="FileTypeselected" v-on:change="onChangeDocType()">
             <option value="" v-if="language.name === 'english'">Choose file type</option>
             <option value="" v-if="language.name === 'welsh'">Dewis math o ffeil</option>
            <option v-for="(item, index) in filetypedata" :key="index" :value="fileType(item.addFile[0].file)">{{fileType(item.addFile[0].file)}}</option>
          </select>
        </div>
       
        <div class="col col-md-4">
          <label for="Category" v-if="language.name === 'english'">Filter By Category</label>
           <label for="Category" v-if="language.name === 'welsh'">Hidlo fesul categori</label>
          <multiselect id="Category" v-if="language.name === 'english'" v-model="categoryselected" tag-placeholder="Add this as new tag" placeholder="Choose file category" label="title" track-by="slug" :options="documentCategoryData" :multiple="true" :taggable="true" @tag="addTag" @input="selectedCatData"></multiselect>
       
        <multiselect id="Category" v-if="language.name === 'welsh'" v-model="categoryselected" tag-placeholder="Add this as new tag" placeholder="Dewis categori y ffeil" label="title" track-by="slug" :options="documentCategoryData" :multiple="true" :taggable="true" @tag="addTag" @input="selectedCatData"></multiselect>
              </div>    
    </div>  
  </form>
    
        <div class="fl w100 table-responsive tbl-wrap">
           
            <table class="table mobile-optimised" id="my-new-table">
                <thead>
                    <tr>
                    <th scope="col"  v-if="language.name === 'english'">Name of Document</th>
                    <th scope="col"  v-if="language.name === 'english'">File Type</th>
                    <th scope="col"  v-if="language.name === 'english'">Actions</th>
                     <th scope="col"  v-if="language.name === 'welsh'">Enw'r ddogfen</th>
                    <th scope="col"  v-if="language.name === 'welsh'"> Math o ffeil</th>
                    <th scope="col"  v-if="language.name === 'welsh'">Camau</th>
                  </tr>
                </thead>
                <tbody>
                
                    <tr v-for="(dataitem,dataIndex) in paginatedItems" :key="dataIndex">
                        <td data-th="Name of Document" v-if="dataitem.fileName!=undefined">{{dataitem.fileName}}</td>
                        <td data-th="File Type" v-if="dataitem.addFile!=undefined"><span class="file-type">{{fileType(dataitem.addFile[0].file)}}</span></td>
                      
                        <td data-th="" v-if="dataitem.addFile!=undefined">
 
                            <button  v-if="language.name === 'english'" class="fl tbl-btn" @click="onClick(dataitem.addFile[0].file)"><span class="fl btn-icon download"></span><span class="fl btn-txt">Download</span></button>
                            <button   v-if="language.name === 'english'" class="fl tbl-btn" @click="urlToClick(dataitem.addFile[0].file)" target="_blank"><span class="fl btn-icon view-icon"></span><span class="fl btn-txt">View</span></button> 
                        <button  v-if="language.name === 'welsh'" class="fl tbl-btn" @click="onClick(dataitem.addFile[0].file)"><span class="fl btn-icon download"></span><span class="fl btn-txt">Lawrlwytho</span></button>
                            <button  v-if="language.name === 'welsh'" class="fl tbl-btn" @click="urlToClick(dataitem.addFile[0].file)" target="_blank"><span class="fl btn-icon view-icon"></span><span class="fl btn-txt" >Gweld</span></button> 
                        
                        </td>
                    
                    </tr>
                </tbody>  
            </table>
        </div>{{tRows}}
    <div class="fl w100 pagi-wrapper mb-5">    
    <b-pagination class="tbl-pagination" style="margin:0 auto;"
    @change="onPageChanged"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-new-table"
    ></b-pagination>
    </div>
    </div>

    </section>
</template>

<script>
import axios from 'axios';

import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import Multiselect from 'vue-multiselect'
 
//import Store from '@/store/index.js'
const _ = require('lodash');
export default {
 props: {
    data: Array,
 currentLanguage: {
      type: Object,
      default() {
        return {
        
          name: "english",
          code: "EN",
        };
      },
    },
  }, 
 
  components: {
    Multiselect
  },
  mounted() {
  
  },
  data() {
      return {

          searchQuery: '',
          FileTypeselected:'',
          categoryselected: [],
          paginatedItems:this.data,
          paginatedItemsArray:[],
          currentPage: 0,
          perPage: 6,
          rows:this.data.length
         
      }
    },
    async created(){
       this.paginatedItemsArray=await  this.filteredItems(this.searchQuery, this.FileTypeselected,this.categoryselected,true);
       this.paginate(this.perPage, this.currentPage,this.paginatedItemsArray );
       this.rows=this.paginatedItemsArray.length
    },
     computed: {
         ...mapGetters(["content","language"]),
         documentCategoryData() {
            if (this.content && this.content.docCat) {
                return this.content.docCat
            }else return {};
        },
      
        filetypedata()
        {
          if (this.data) {
              var newArr=_.uniqBy(this.data,  function (e) {
                if(e.addFile)
                {
                  return e.addFile[0].contentType; 
                }   
              });
            return newArr;
          } else return {};
        },  
    },
      watch: {
       
      },  
   methods: {
     onChangeDocType(){
       this.FileTypeselected= document.getElementById('FileType').value;
        this.paginatedItemsArray=this.filteredItems(this.searchQuery, this.FileTypeselected,this.categoryselected,true);
       this.paginate(this.perPage, 0,  this.paginatedItemsArray);
        this.rows=this.paginatedItemsArray.length
     },
     keymonitor(){
         this.paginatedItemsArray=this.filteredItems(this.searchQuery, this.FileTypeselected,this.categoryselected,true);
       this.paginate(this.perPage, 0,  this.paginatedItemsArray);
        this.rows=this.paginatedItemsArray.length
      },
      selectedCatData(){
         this.paginatedItemsArray=this.filteredItems(this.searchQuery, this.FileTypeselected,this.categoryselected,true);
         this.paginate(this.perPage, 0,  this.paginatedItemsArray);
          this.rows=this.paginatedItemsArray.length
      },
   addTag (newTag) {
      const tag = {
        title: newTag,
        slug: newTag
      }
      this.categoryselected.push(tag)
    },
  KeyWordsList(keyWordArray){
      let arr = [];
      if(keyWordArray.length>0){
          keyWordArray.forEach((value, index) => {
              arr[index]=value.word;
          });
          return JSON.stringify(arr);
      }else{
        return arr;
      }
  },
  getCateoryList(categoryArray){
       let arr = [];
      if(categoryArray.length>0){
          categoryArray.forEach((value, index) => {
              arr[index]=value.slug;
          });
          return JSON.stringify(arr);
      }else{
        return arr;
      }
  },

       paginate(page_size, page_number, items) {
          
        let itemsToParse = items;
       
          this.paginatedItems=  itemsToParse.slice(
              page_number * page_size,
              (page_number + 1) * page_size
          );
     
        },
         onPageChanged(page) {
            this.paginate(this.perPage, page - 1,this.paginatedItemsArray );
        },
         onClick(docName) {
              axios({
                
                    url: 'https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2F' + docName+'?alt=media',
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/pdf' }));
                     var fileLink = document.createElement('a');
   
                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', docName);
                     document.body.appendChild(fileLink);
   
                     fileLink.click();
                     this.$gtag.event('button', {
                      'event_category': 'download',
                      'event_label': docName,
                      'value': 'pdf'
                    })
                })
                 .catch(() => console.log('error occured'));
          },
    fileType(docName){
        var name=docName.split('.');
        return name[name.length - 1]
    },
    
    urlToClick(docName){  
       var url= "https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2F" + docName+'?alt=media';
       this.$gtag.event('button', {
                      'event_category': 'view',
                      'event_label': docName,
                      'value': 'pdf'
                    })
       window.open(url, "_blank").focus();   
       
    },
 filteredItems(searchString,fileTypeString,categoryArray,filter){
        console.log('ValuesSearch:',searchString,'|||ValuesFileTye:',fileTypeString,'|||ValuesCat:',categoryArray)
        var arr=[]
        arr =this.getCateoryList(categoryArray);
      this.paginatedItems=this.data;
       if(searchString!='' &&   fileTypeString==''&& categoryArray.length==0){
            if(this.searchQuery){
             this.paginatedItemsArray= this.data.filter((item)=>{ 
                return this.searchQuery.toLowerCase().split(' ').every(v => (item.fileName.toLowerCase().includes(v)) || (item.keyWords.length>0?(this.KeyWordsList(item.keyWords).toLowerCase().includes(v)  ):'') )
              });
              
            }
         return this.paginatedItemsArray;
       }else if(fileTypeString!=''  && searchString=='' && categoryArray.length==0){console.log('only filetype')
             this.paginatedItemsArray= this.data.filter((item)=>{console.log('FILE',item.addFile[0].file)
                  return item.addFile[0].file.toLowerCase().includes(fileTypeString)
              });
            return this.paginatedItemsArray;
       }else if(categoryArray.length>0 && fileTypeString==''  && searchString==''){console.log('only category')

           this.paginatedItemsArray= this.data.filter((item)=>{
                  if(item.category.length>0){
                      return _.find(item.category, (key) =>{ 
                        return arr.includes(key.slug)
                      })
                  }else{
                      return false
                  }
              });
          return this.paginatedItemsArray;
       }
       else if(searchString!='' && fileTypeString!='' && categoryArray.length==0 ){console.log('search && filetype')
         
         var filterdfiletype=[];
          filterdfiletype= this.data.filter((item)=>{ 
                 return item.addFile[0].file.toLowerCase().includes(fileTypeString)
              });
                this.paginatedItemsArray= filterdfiletype.filter((item)=>{
                  return this.searchQuery.toLowerCase().split(' ').every(v => (item.fileName.toLowerCase().includes(v)) || (item.keyWords.length>0?(this.KeyWordsList(item.keyWords).toLowerCase().includes(v)  ):'') )
              });
            return this.paginatedItemsArray;
       }else if(searchString!='' && categoryArray.length>0 && fileTypeString==''){console.log('search && category')
              var filtered=[]
              filtered= this.data.filter((item)=>{ 
                  if(item.category.length>0){
                      return _.find(item.category, (key) =>{ 
                        return arr.includes(key.slug)
                      })
                  }else{
                      return false
                  }
              });
              console.log('FilteredCat', filtered)
             this.paginatedItemsArray= filtered.filter((item)=>{
                  return this.searchQuery.toLowerCase().split(' ').every(v => (item.fileName.toLowerCase().includes(v)) || (item.keyWords.length>0?(this.KeyWordsList(item.keyWords).toLowerCase().includes(v)  ):'') )
              })
              return this.paginatedItemsArray;
       }else if(searchString=='' && fileTypeString!=''  && categoryArray.length>0){console.log('filetype && category')
              var filteredTypeCat=[]
              filteredTypeCat= this.data.filter((item)=>{ 
                  if(item.category.length>0){
                      return _.find(item.category, (key) =>{ 
                        return arr.includes(key.slug)
                      })
                  }else{
                      return false
                  }
              });
              this.paginatedItemsArray= filteredTypeCat.filter((item)=>{
                return item.addFile[0].file.toLowerCase().includes(fileTypeString)
              });
              return this.paginatedItemsArray;
       }
       else if(searchString!='' && fileTypeString!=''  && categoryArray.length>0){console.log('search && filetype && category')
              var filteredSearchTypeCat=[]
              var filteredfiletype=[]

              filteredSearchTypeCat= this.data.filter((item)=>{ 
                  if(item.category.length>0){
                      return _.find(item.category, (key) =>{ 
                        return arr.includes(key.slug)
                      })
                  }else{
                      return false
                  }
              });

           filteredfiletype= filteredSearchTypeCat.filter((item)=>{
                return item.addFile[0].file.toLowerCase().includes(fileTypeString)
              });
              
               this.paginatedItemsArray= filteredfiletype.filter((item)=>{
                  return this.searchQuery.toLowerCase().split(' ').every(v => (item.fileName.toLowerCase().includes(v)) || (item.keyWords.length>0?(this.KeyWordsList(item.keyWords).toLowerCase().includes(v)  ):'') )
              });
              return this.paginatedItemsArray;
       }else if(!filter){console.log('no filters')
            return this.data;
       }else{          
            this.paginatedItemsArray=this.data;
            return this.paginatedItemsArray;
       }
          
      },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
$cardDeepBlue: #536f90;
$cardDeepGrey: #727d93;
body .LandingPage section.documentLibraryBackImage{
  padding: 0;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #1A544B!important;
    border-color: #1A544B!important;
}

.page-item .page-link {
   
   color: #1A544B;
    
}
.multiselect{
  float: left;
  border: 1px solid #1A544B;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    padding-top: 5px;
    background: transparent;
    min-height: 53px;
}
.multiselect__content-wrapper{
  margin-top: 7px;
}
.multiselect__tags{
  background: transparent;
  border: none;
}
body .frm-wrap .col input[type='text'].multiselect__input{
  padding: 0;
  border: none;
  padding-top: 3px;
}
.multiselect__select{
  height: 45px;
}
.multiselect__option--highlight{
  background: #1a544b;
}
.multiselect__option--highlight::after{
  background: #1a544b;
}
.multiselect__placeholder{
  color: #000;
}
.multiselect__tag{
  background: #1a544b;
}
.multiselect__tag-icon:after{
  color: #fff;
}
</style>
