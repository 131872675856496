<template>
  <div class="overlay-loading">
    <div class="loading-logo"></div>
    <div class="loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style></style>
