import Vue from 'vue';
import Vuex from 'vuex';
// import{ENData, WLData} from '@/services/MultiLangData.js'
// import apiWL from '@/services/FlamelinkDataWL.js'
// import apiEN from '@/services/FlamelinkData.js'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: {
      name: 'english',
      code: 'English'
    },
    showImage: false,
    content_EN: [],
    content_WL: [],
    blogType: ['press', 'blog'],
    feedback: {},
    results: {
      isLoading: true
    },
    loadingStatus: true
    // resultsWL: {
    // 	isLoading: true,
    // },
  },
  mutations: {
    toggleImage(state, payload) {
      state.showImage = !state.showImage;
    },
    toggleLanguage(state, payload) {
      if (state.language.name === 'english' && payload === 'welsh') {
        //console.log('1');
        state.language = {
          name: 'welsh',
          code: 'Cymraeg'
        };
      } else if (state.language.name === 'english' && payload === 'english') {
     //   console.log('2');
        state.language = {
          name: 'english',
          code: 'English'
        };
      } else if (state.language.name === 'welsh' && payload === 'english') {
       // console.log('3');
        state.language = {
          name: 'english',
          code: 'English'
        };
      } else if (state.language.name === 'welsh' && payload === 'welsh') {
        //console.log('4');
        state.language = {
          name: 'welsh',
          code: 'Cymraeg'
        };
      }
    },
    storeFeedback(state, payload) {
      state.feedback = payload;
    },
    // storeResults(state, payload) {console.log('payload',payload)
    //         if (payload === 'english') {console.log('Final Result EN', state.content_EN)
    //             state.results = state.content_EN
    //         }
    //         else if (payload === 'welsh') {console.log('Final Result WL', state.content_WL)
    //             state.results = state.content_WL
    //         }else{console.log('Final Result ENELSE', state.content_EN)
    //             state.results = state.content_EN
    //         }
    // 	// state.results = payload
    // },
    // addToResults(state, payload) {
    // 	state.results = { ...state.results, ...payload }
    // },
    storeEnglishContent(state, payload) {
      state.content_EN = payload;
    },
    storeWelshContent(state, payload) {
      state.content_WL = payload;
    },
    SET_BLOG_TYPE(state, payload) {
      state.blogType = payload;
    },
    loadingStatus(state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus;
    },
    cancelIsLoading(state) {
      state.results.isLoading = false;
    }
  },
  actions: {
    SET_BLOG_TYPE({ commit }, blogType) {
      commit('SET_BLOG_TYPE', blogType);
    },
    toggleImage({ commit }, data) {
      commit('toggleImage', data);
    },
    toggleLanguage({ commit }, language) {
      commit('toggleLanguage', language);
    },
    saveFeedbackData({ commit }, data) {
      commit('storeFeedback', data);
    },
    // storeResults({ commit }, payload) {console.log('i m in Store')
    //     if(payload=='english'){
    //         commit('storeResults', payload)
    //     }else if(payload=='welsh'){
    //         commit('storeResults', payload)
    //     }

    // },
    // addToResults({ commit }, payload) {
    //     commit('addToResults', payload)
    // },
    storeLoadingStatus({ commit }, payload) {
    //  console.log('LoadingStatus', payload);
      commit('loadingStatus', payload);
    },
    storeResultsWL({ commit }, payload) {
      //console.log('i m in welsh save');

      commit('storeWelshContent', payload);
    },
    storeResultsEN({ commit }, payload) {
     // console.log('i m in english save');
      commit('storeEnglishContent', payload);
    }
  },

  getters: {
    language: state => state.language,
    feedback: state => state.feedback,
    blogType: state => state.blogType,
    showImage: state => state.showImage,
    // blogCat:state=>state.blogCat,
    content: state => {
      if (state.language.name === 'english') {
        return state.content_EN;
      } else if (state.language.name === 'welsh') {
        return state.content_WL;
      } else {
        return state.content_EN;
      }
    },
    loadingStatus: state => state.loadingStatus
  },

  method: {
    GetLanguage() {
      return 'returned Dtaa'; //this.state.language""
    }
  }
});
