<template>
  <section class="radio-buttons blogType-wrap">
    <div class="container">
        <div class="row">
            <div class="btn-group btn-group-toggle" data-toggle="buttons" id="checkbox">
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <label class="btn btn-toggle active"  v-for="(items, index) in blogCategory" :key="index" v-if="index==0">
                    <input type="checkbox" name="options" :id="lowerCase(items.slug)" checked="ifChecked(items.slug)"> 
                   {{items.title}}
                </label>
                <label class="btn btn-toggle active" v-for="(items, index) in blogCategory" :key="index" v-if="index>0">
                    <input type="checkbox" name="options" :id="lowerCase(items.slug)" checked="ifChecked(items.slug)"> {{items.title}}
                </label>
               
            </div>
        </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import Store from '@/store/index.js'
const _ = require('lodash');
const $ = require('jquery');
export default {
   props: {
    blogCategory: Array,
   },

    mounted(){
        $(document).ready(() =>{ 
            var self = this;
            $(document).on('change','input[type=checkbox][name=options]', (event) =>{
                var content=[]
                var filterValue=$("input[name='options']:checked")
                var checkedElements = Array.prototype.map.call(filterValue, function (el, i) {
                        return content.push(el.id)
                });
                Store.dispatch('SET_BLOG_TYPE',content)
            })
        })
    },
   computed: {
        ...mapGetters(["content","blogType"]),
       /* radioButtonData() {
            if (this.content && this.content.blog) {
                return _.chain(this.content.blog).map('category').uniq().value()
            } else return {};
        },*/
       /*  blogCategory(){
      if (this.content && this.content.blogCat) {
        return this.content.blogCat;
        } else return {};
    }*/
    },
    methods:{
        lowerCase(name){
            return name.toLowerCase();
        },
        ifChecked(name){
            if( this.blogType){
                if(this.blogType.includes(name)==true){
                    return 'checked'
                }
            }else{ 
                return '';
            }
        }
    }
};

</script>
<style lang="scss">
body .btn.btn-toggle{
    background: #fff !important;
    border: 2px solid #1A544B !important;
    color:#1A544B !important;
    border-radius: 5px !important;
    margin-right: 20px;
    &.active{
        background: #1A544B !important;
        color:#fff !important;
    }
}
</style>
