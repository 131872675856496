import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import flamelink from 'flamelink/app';
import 'flamelink/content';
import 'flamelink/storage';
const firebaseConfig = {
  databaseURL: 'https://newgalecms.firebaseio.com',
  apiKey: 'AIzaSyBIQ6IV9PkedXsib19yTpG85OMHq37DR08',
  authDomain: 'newgalecms.firebaseapp.com',
  projectId: 'newgalecms',
  storageBucket: 'newgalecms.appspot.com',
  messagingSenderId: '62201269188',
  appId: '1:62201269188:web:dc81d1840c440dbcd57291',
  measurementId: 'G-MG92E380H6'
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const AppEN = flamelink({
  firebaseApp,
  env: 'production',
  locale: 'en-US',
  dbType: 'cf'
});
const AppWL = flamelink({
  firebaseApp,
  env: 'production',
  locale: 'wales-england',
  dbType: 'cf'
});

export const flamelinkApp = locale =>
  flamelink({
    firebaseApp,
    dbType: 'cf',
    locale,
    env: 'production'
  });
// variable not being used yet, trying to implement as a vue global varable by using vue plugin
const FlamelinkPlugin = {
  install(Vue, options) {
    Vue.prototype.$flamelinkApp = AppEN;
    Vue.flamelinkApp = AppEN;
  }
};
const FlamelinkPluginWelsh = {
  install(Vue) {
    Vue.prototype.$flamelinkAppWL = AppWL;
    Vue.flamelinkAppWL = AppWL;
  }
};

export { AppEN, AppWL, FlamelinkPlugin, FlamelinkPluginWelsh };
