<template>
  <section class="site-cards">
    
           
    <div class="container">
      <div class="fl w100 ToolBar">
          <div class="fl">
            <label for="blogType" class="fl w100"  v-if="language.name === 'english'">Show :</label>
             <label for="blogType" class="fl w100"  v-if="language.name === 'welsh'">Dangos :</label>
            <RadioButtons :blogCategory="blogCategory"  v-model="blogType"/>
          </div>
          
      </div>
      <div class="row">
        <div class="site-card-col col-sm-12 col-md-6 col-lg-3 col-xl-4" v-for="(items, index) in this.data" :key="index">
            <router-link class="site-card-news" :to="urlPath(items.slug)">
              <div class="site-card-title white-text">
                <h4>{{ items.newsCardTitle }}</h4>
              </div>
               <div class="text-bottom"><span class="fl">{{formatDate(items.date)}}</span><span class="fl divider">|</span><span class="fl">{{ items.categoryType.title }}</span></div>
              <div class="image-bottom"><img src="../assets/images/cardbottom.png" alt="decoration"></div>
            </router-link>
        </div>
      </div>
    </div>
   <div class="fl w100 pagi-wrapper mt-5">    
    <b-pagination class="tbl-pagination" style="margin: 0 auto;"
    @change="onPageChanged"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-new-table"
    ></b-pagination>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import Dropdown from "../components/Dropdown";
import RadioButtons from "../components/RadioButtons";
import { mapGetters } from "vuex";
  const _ = require('lodash');
export default {
   mounted() {
        
  },
  data() {
      return {
       // blogType:'',
            paginatedItems:this.data,
            currentPage: 1,
            perPage: 4,
            paginatedItemsArray:[],
            rows:this.data.length
      }
    },
    async created(){
      this.paginatedItemsArray= await  this.filteredData(this.blogType);
      console.log('RRR:::',this.paginatedItemsArray, )
      this.paginate(this.perPage, 0,this.paginatedItemsArray );
      this.rows=this.paginatedItemsArray.length
    },
     watch: {

       data:function(val,oldval){console.log("KKKKKK", val)},
    
    blogType (newCount, oldCount) {
      console.log(`We have ${newCount} , yay!`)
      this.onChangeDocType()
      this.paginate(this.perPage, 0,  this.paginatedItemsArray);
      this.rows=this.paginatedItemsArray.length
    }

  },
  components: {
    Dropdown,
    RadioButtons,
   
  },

  props: {
    data: Array,
  },
  
   computed: {
    ...mapGetters(["blogType","content","language"]),
    blogCategory(){
      if (this.content && this.content.blogCat) {
        return this.content.blogCat;
        } else return {};
    }
     
  },
   methods: {
    urlPath(url) {
      
         return 'blog/' + url;
    },
    catSlug(item){
      if(item.slug!=''){
       return item.slug
      }else{
        return ''
      }
    },
    formatDate(value){
      return moment(String(value)).format('DD MM YYYY')
    },
    onChangeDocType(){
        this.paginatedItemsArray=this.filteredData(this.blogType);
        this.paginate(this.perPage, 0,  this.paginatedItemsArray);
        this.rows=this.paginatedItemsArray.length
    },
    filteredData(arr){
     
      var orderedArray=_.orderBy(this.data, 'date', 'asc');
      console.log("$$$AAA", orderedArray);
       this.paginatedItems=orderedArray;
        if (this.data) {
              if(arr.length>0 ){
                 this.paginatedItemsArray=  orderedArray.filter((item)=> {
                    if(item.category!=''){console.log(arr,item.category)
                        return  arr.includes(item.category)
                  }else{
                      return false
                  }
                })
                console.log('PArray',this.paginatedItemsArray)
                return this.paginatedItemsArray;

             }
            
             else{
                  this.paginatedItemsArray= orderedArray
                  return this.paginatedItemsArray;
             }
     
        } else return {};
    },
    paginate(page_size, page_number,items) {
       
        let itemsToParse = items;
        this.paginatedItems= itemsToParse.slice(
            page_number * page_size,
            (page_number + 1) * page_size
        );
      
    },
      onPageChanged(page) {
        this.paginate(this.perPage, page - 1, this.paginatedItemsArray);
    },   
  }
};
</script>
<style lang="scss">
$cardDeepBlue: #536f90;
$cardDeepGrey: #727d93;
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #1A544B!important;
    border-color: #1A544B!important;
}
.radio-buttons .container{
  max-width: 100% !important;
}
.page-item .page-link {
   
   color: #1A544B;
    
}
.ToolBar label{
  color: #1A544B;
}
body .LandingPage section.site-cards{
  padding-top: 0 !important;
}
.site-cards {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .col {
      margin: 0 0 20px;
      float: none;
    }
  }
}
.image-bottom{
    position:relative ;
    img{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
.text-bottom{
     position:relative ;
    p{
    position: absolute;
    bottom: 20px;
    left: 20px;
    }
}
.site-cards {
  margin-bottom: 30px;
}

.site-card-col {
  margin-bottom: 30px;
}

.site-card-news {
  display: flex;
  flex-direction: column;
  height: 100%;
 
  .site-card-title {
        height: 270px;
    background: white;
    padding: 30px;
    font-size: 16px;
    flex: 1 0 auto;
    overflow: hidden;

    h4{
      color: #1A544B;
      font-size:24px;
      font-family: DM Serif Text;
      line-height: 30px;
    }
    p{
       font-size:16px;
    }
  }
  &:hover {
    text-decoration:none!important;
    .site-card-image p {
      opacity: 1;
    }
    .site-card-title {
       font-weight: 400;
    }
  }

}
.text-bottom{
  height: 80px;
  background: #fff;
  padding: 0 30px;
  span{
    padding: 0 3px;
    &.divider{
      color: #000 !important;
    }
  }
}
.site-card-col a{
  color: #2A8476;;
  &:hover{
    color: #2A8476;;
  }
}
</style>