import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import Cookies from '../views/CookiePolicy';
import FAQ from '../views/FAQ';
import AccordionCard from "../components/FAQ/AccordionCard.vue";
 import _ from "lodash";
import { getMainNav } from "../services/Routes.js";
Vue.use(VueRouter);

const prepareRoute = route => {
    return {
      path: '/'+route.pageSlug + ( route.subRoutes ? '/:slug' : ''),
      name: route.pageName,
      component: () => import(`../views/${route.componentName || "NotFound"}.vue`),
    };
  
  };


  const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
  const newRouteData = [
    {
        path: '/cookies',
        name: 'Cookies'
    },
/*      {
          path: '/faq',
          name: 'FAQ'
      },*/
];
  const getRouter = async () => {
    const navItems = await getMainNav(); 
        Object.values(navItems).forEach(val => {
            newRouteData.push(val)
        });
     const routes = newRouteData.sort((a, b) => a.order >= b.order).map(prepareRoute); 
    return new VueRouter({
        routes,
        scrollBehavior: function (to) {
            if (to.hash) {
                return {
                    selector: to.hash
                }
            }
        },
    });
  };

  
  export default getRouter;
