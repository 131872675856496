<template>
<section>
<div class="container py-5">
    <div class="row">
        <div class="col-md-12">
            <div class="main-timeline">
          

                 <div class="timeline"  v-for="(item,index) in timelineContentData" :key="index">
                    <div class="timeline-content">
                        <div class="circle">
                        <span class="homebox">
                        <img :src="imgPath(item.image[0].file)" :alt="item.altText"/>
                        
                         </span></div>
                        <div class="content">
                            <h3 class="title h4">{{item.header}}</h3>
                            <p class="description" v-html="item.text"></p>
                            <p class="link" v-if="item.link==true"><a :href="item.linkLink"  class="atag" v-html="item.linkText"></a>  <i class="fas fa-angle-right"></i>  </p>
                        </div>
                    </div> 
                </div>
                
            </div>
        </div>
    </div>
</div>
</section>
</template>

<script>
const _ = require('lodash');
import { mapGetters } from "vuex";
export default {
  name: 'TimelineTemplate',
  props: {
    msg: String
  },
  computed: {
    ...mapGetters(["content"]),
     timelineContentData() {
       
       if (this.content && this.content.timeline) {
         return _.orderBy(this.content.timeline, 'orderNumber', 'asc');
       }else return {};
    },
   
},

 methods: {
    imgPath(imageName) {
         return 'https://firebasestorage.googleapis.com/v0/b/newgalecms.appspot.com/o/flamelink%2Fmedia%2F' + imageName+'?alt=media';
    },
},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-timeline{ position: relative; }
.main-timeline:before,
.main-timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline:before{
    content: "";
    width: 2px;
    height: 100%;
    background: #2A8476;
    position: absolute;
    top: 0;
    left: 50%;
}
.main-timeline .timeline{
    width: 50%;
    float: left;
    position: relative;
    z-index: 1;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline .timeline:first-child:before,
.main-timeline .timeline:last-child:before{
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: none;
    border:none;
    position: absolute;
    top: 0;
    right: -14px;
    z-index: 1;
}
.main-timeline .timeline:last-child:before{
    top: auto;
    bottom: 0;
}
.main-timeline .timeline:last-child:nth-child(even):before{
    right: auto;
    left: -12px;
    bottom: -2px;
}
.main-timeline .timeline-content{
    text-align: center;
    margin-top: 8px;
    position: relative;
    transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before{
    content: "";
    width: 20%;
    height: 2px;
    background: #2A8476;
    position: absolute;
    top: 75px;
    right: 0;
    z-index: -1;
}
.main-timeline .circle{
    width: 417px;
    height: 212px;
    border-radius: 0;
    background: #fff;
    border: none;
    float: right;
    margin-right: 40px;
    position: relative;
}

.main-timeline .circle span{
    display: block;
    width: 417px;
    height: 212px;
    font-size: 80px;
    color: #454344;
    overflow: hidden !important;
}
 
.main-timeline .circle span:after{
    border-radius: 21px 0 0 0;
    top: 0;
    bottom: -56px;
}
.main-timeline .circle span img{
    vertical-align: initial;
     width: 417px;
}
.main-timeline .content{
    display: inline-block;
    padding-right: 40px;
    position: relative;
    width:460px;
    float:right;
    margin-top: 1rem;
}
.main-timeline .year{
    display: block;
    padding: 10px;
    margin: 10px 0 50px 0;
    background: rgba(211,207,205,1);
    border-radius: 7px;
    font-size: 25px;
    color: #fff;
}
.main-timeline .title{
    font-size: 35px;
    font-weight: bold;
    color: #1A544B;;
    margin-top: 0;
    text-align: left;
    font-family: DM Serif Text;
}
.main-timeline .description{
    font-size: 14px;
    color: #333;
    text-align: left;
}
.main-timeline .link{
    font-size: 16px;
    color:   #2A8476;
    line-height:26px;
    text-decoration: none;
    text-align: left;
    
}
.main-timeline a{
      color:   #2A8476;
}
.main-timeline i{
    font-size: 13px;
    padding-left: 2px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content:before{
    left:0
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle{
    float: left;
}
.main-timeline .timeline:nth-child(2n) .circle{
    margin: 0 0 0 40px;
}
.main-timeline .timeline:nth-child(2n) .circle:before{
    right: auto;
    left: -33px;
    box-shadow: -7px 0 9px -7px #444 inset;
}

.main-timeline .timeline:nth-child(2n) .circle span:before,
.main-timeline .timeline:nth-child(2n) .circle span:after{
    right: auto;
    left: -33px;
    border-radius: 0 0 21px 0;
}
.main-timeline .timeline:nth-child(2n) .circle span:after{
    border-radius: 0 21px 0 0;
}
.main-timeline .timeline:nth-child(2n) .content{
    padding: 0 0 0 40px;
    margin-left: 2px;
    float:left;
}

.main-timeline .timeline:nth-child(2){
    margin-top: 180px;
}
.main-timeline .timeline:nth-child(odd){
    margin: -175px 0 0 0;
}
.main-timeline .timeline:nth-child(even){
    margin-bottom: 180px;
}
.main-timeline .timeline:first-child,
.main-timeline .timeline:last-child:nth-child(even){
    margin: 0;
}


.timelineimg{
    
    width: 333px!important;
    
}
/* MEDIA QUERIES */
@media (min-width: 320px) and (max-width: 989px){
.main-timeline .circle{
        width: 100%;
        height: 100%;
        float:left;
        margin-right: 0;
        background: transparent;
    }
    .main-timeline .circle span img{
        width: 100%;
    }
    .main-timeline .timeline-content::before, .main-timeline::before{
        background: transparent;
        height: 0;
    }
    .main-timeline .circle span{
        width: 100%;
        height: 100%;
    }
    .main-timeline .content{
        width: 100%;
        margin-top: 0;
        float: left;
        padding: 0;
    }
    .main-timeline .timeline:nth-child(2n) .circle{
        margin: 0 !important;
        float: left !important;
    }
    .main-timeline .timeline:nth-child(2n) .content{
        padding: 0 !important;
        float: left;
    }
}


@media only screen and (max-width: 990px){
    .main-timeline:before{ left: 100%; }
    .main-timeline .timeline{
        width: 100%;
        float: none;
        margin-bottom: 20px !important;
    }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before{
        left: auto !important;
        right: -13px !important;
    }
    .main-timeline .timeline:nth-child(2n) .circle{
        float: right;
    margin: 0 40px 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .circle:before{
        right: -33px;
        left: auto;
        box-shadow: 7px 0 9px -7px #444 inset;
    }

    .main-timeline .timeline:nth-child(2n) .circle span:before,
    .main-timeline .timeline:nth-child(2n) .circle span:after{
        right: -33px;
        left: auto;
        border-radius: 0 0 0 21px;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:after{
        border-radius: 21px 0 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .content{
        padding: 0 40px 0 0;
        
         float:right;
    }
      .main-timeline .timeline:nth-child(2n) .timeline-content:before{
    right:0!important;    left: unset;
}
    .main-timeline .timeline:nth-child(2),
    .main-timeline .timeline:nth-child(odd),
    .main-timeline .timeline:nth-child(even){
        margin: 0;
    }
}

</style>
