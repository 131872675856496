<template>
  <div class="" id="footer">
  <footer>
        <cookie-law theme="dark-lime"></cookie-law>
      </footer>
      <div class="container d-flex h-100">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 w-100 justify-content-center align-self-center footer-wrapper">
           
              <div class="col mx-auto ">
                  <div class="footer-logo"></div>
                  <div  class="footerText mb-3" v-html="footerData.colum1">
                   </div>                    
              </div>
              <div class="col mx-auto">
                <div  class="footerText" v-html="footerData.colum2">
                </div>
              </div>
              <div class="col mx-auto">
                <div  class="footerText" v-html="footerData.colum3"></div> 
                </div>
            </div>
        </div>
               
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CookieLaw from "vue-cookie-law";
export default {
  name: "Footer",
  components: {
    CookieLaw,
  },
  props: {
   
  },
   computed: {
    ...mapGetters(["content"]),
    footerData() {
      if (this.content && this.content.footer) {
         return this.content.footer[0];
       }else return {};
    },
  }
};
</script>

<style lang="scss">
.footer-wrapper{
  background: #2A8476;
}

.footerText {
  font-family: Museo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 35px;
  a{
    color:white!important;
   
    &:hover{
      text-decoration:underline;
    }
  }
  ul{
    list-style: none;
    li{ 
      list-style: none;
      &:first-child{
        font-size: 16px;
        padding-bottom: 10px;;
      }
    }
  }


 
}

.Cookie--dark-lime .Cookie__button
{
  background: #2A8476 !important;
    border: 2px solid #2A8476;
    border-radius:8px!important;
}
.Cookie--dark-lime .Cookie__button:hover
{
  background: white !important;
  color:#2A8476 !important;
    
}
</style>

